<script setup>
/******************************************************************************
 * PageComponent: SingleCustomerData. Diese Komponente wird nur angezeigt wenn es genau
 * einen Kunden gibt.
 *****************************************************************************/
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const customerData = data.customers[0];
</script>

<template>
    <div class="customer-data">
        <svg-icon name="customer" :size="0" />
        <span class="text">
            <strong>Vertragsnehmer:</strong>
            {{ customerData.salutation }}
            {{ customerData.name }}
            {{ customerData.lastName }}
            {{ customerData.nameAddon }}
            <cite title="Ihre Kundennummer" aria-label="Ihre Kundennummer">{{
                customerData.customerNo
            }}</cite>
        </span>
    </div>
</template>

<style lang="scss" scoped>
.customer-data {
    display: flex;
    align-items: center;

    padding: #{get($sizes, "base") * 0.2};
    border: get($sizes, "border") solid
        get($client, "singleCustomerBorderColor");
    margin: 0 0 #{get($sizes, "base") * 0.6} 0;
    gap: #{get($sizes, "base") * 0.2};

    background-color: get($client, "singleCustomerBg");
    color: get($client, "singleCustomerColor");
    border-radius: get($sizes, "radius");

    @include respond-to("medium") {
        margin: 0 0 #{get($sizes, "base") * 1.2} 0;
    }

    cite {
        font-style: normal;
        font-weight: bolder;
    }
}
</style>
