<script setup>
/******************************************************************************
 * PageComponent: Meter Header
 *****************************************************************************/
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    meterNumber: {
        type: String,
        required: true,
    },
});
const meterData = data.getMeterData(props.meterNumber);
const customerData = data.getCustomerData(meterData.customerId);
const mediaType = data.getMediaTypeData(meterData.meterMediaType);
</script>

<template>
    <header>
        <div class="row1">
            <h1 class="hdl">
                <svg-icon :name="mediaType.icon" />{{ mediaType.label }}-Zähler
            </h1>
            <span class="meter-point">
                <span class="text">
                    <svg-icon name="meter" :size="0" />
                    Zählernummer
                </span>
                <span class="number">{{ meterData.meterNumber }}</span>
            </span>
        </div>
        <div class="row2">
            <aside
                v-if="
                    data.customers.length > 1 &&
                    data.getCustomersForMeter(meterNumber).length === 1
                "
                class="customer"
                :aria-label="'Kundeninformation KNR ' + customerData.customerNo"
            >
                <svg-icon name="customer" :size="0" />
                <span class="text">
                    <strong>Vertragsnehmer:</strong>
                    {{ data.getCustomerString(customerData.customerId) }}
                    <cite
                        title="Ihre Kundennummer"
                        aria-label="Ihre Kundennummer"
                        >{{ customerData.customerNo }}</cite
                    >
                </span>
            </aside>
            <aside
                class="collection-point"
                :aria-label="'Abnahmestelle Zählernummer ' + meterNumber"
            >
                <svg-icon name="location" :size="0" />
                <span class="text">
                    <strong aria-hidden="true">Abnahmestelle:</strong>
                    {{ data.getMeterAdress(meterNumber) }}
                </span>
            </aside>
        </div>
    </header>
</template>

<style lang="scss" scoped>
header {
    display: flex;
    flex-direction: column;

    padding: #{get($sizes, "base") * 0.25};
    gap: #{get($sizes, "base") * 0.2};

    background-color: get($client, "meterHeaderBg");
    color: get($client, "meterHeaderColor");
    border-radius: #{get($sizes, "radius")} #{get($sizes, "radius")} 0 0;

    @include respond-to("medium") {
        padding: #{get($sizes, "base") * 0.5};
    }
}

.hdl {
    display: flex;
    align-items: center;

    margin: 0;
    gap: #{get($sizes, "base") * 0.5};

    font-size: #{get($sizes, "base") * 1.2};
    font-weight: 400;
    line-height: 1.4;

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 0.75};

        .icon {
            width: 32px;
            height: 32px;
            flex-basis: 32px;
        }
    }
}

.row1 {
    display: flex;
    flex-direction: row;

    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 0.75};
    }
}

.row2 {
    display: flex;
    flex-direction: column;

    gap: #{get($sizes, "base") * 0.2};

    @include respond-to("medium") {
        justify-content: space-between;
        flex-direction: row;
    }
}

.customer,
.meter-point,
.collection-point {
    padding: #{get($sizes, "base") * 0.2};
    border: get($sizes, "border") solid
        get($client, "meterHeaderAsideBorderColor");

    background-color: get($client, "meterHeaderAsideBg");
    border-radius: get($sizes, "radius");
}

.meter-point {
    display: flex;
    align-items: center;

    margin-left: auto;

    > .text {
        display: none;

        @include respond-to("medium") {
            display: inline-flex;
            align-items: center;

            margin-right: #{get($sizes, "base") * 0.5};
            gap: #{get($sizes, "base") * 0.2};
        }
    }

    > .number {
        color: palette("brand1", "color");
    }
}

.customer,
.collection-point {
    margin-right: auto;

    @include respond-to("medium") {
        margin-right: 0;
    }
}

.customer {
    display: flex;
    align-items: center;

    gap: #{get($sizes, "base") * 0.2};

    cite {
        color: palette("brand1", "color");

        font-style: normal;
    }

    @include respond-to("medium") {
        margin-right: auto;
    }
}

.collection-point {
    display: flex;
    align-items: center;

    gap: #{get($sizes, "base") * 0.2};
}
</style>
