<script setup>
/******************************************************************************
 * component: Header logout button
 *****************************************************************************/
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useAuthStore } from "Stores/auth";
const auth = useAuthStore();
</script>

<template>
    <button
        class="logout"
        title="Abmelden"
        aria-label="Abmelden"
        @click="auth.logout"
    >
        <span class="text">Abmelden</span>
        <svg-icon name="logout" />
    </button>
</template>

<style lang="scss" scoped>
.logout {
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: content-box;
    padding: calc(#{get($sizes, "base") * 0.4} - #{2 * get($sizes, "border")});
    border: get($sizes, "border") solid #fff;

    background-color: get($client, "logoutButtonBg");
    color: get($client, "logoutButtonColor");
    border-radius: get($sizes, "radius");

    font-weight: 300;

    transition: color get($timings, "fast") linear,
        background-color get($timings, "fast") linear;

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 0.5};
    }

    @include respond-to("large") {
        padding: calc(
            #{get($sizes, "base") * 0.5} - #{2 * get($sizes, "border")}
        );
    }

    &:hover {
        background-color: palette("brand2", "color");
        color: #fff;
        border-color: palette("brand2", "color");

        cursor: pointer;
    }
}

.text {
    display: none;

    @include respond-to("medium") {
        display: block;
    }
}

.icon {
    width: 20px;
    height: 20px;

    @include respond-to("medium") {
        width: 24px;
        height: 24px;
    }
}
</style>
