<script setup>
/******************************************************************************
 * PageComponent: ContactDataSubmissionChannel
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { computed } from "vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    customerId: { type: String, required: true },
    clientId: { type: Number, required: true },
    what: { type: String, required: true },
    icon: { type: String, required: true },
    label: { type: String, required: true },
    inputmode: { type: String, default: "tel" },
    length: { type: Number, default: 120 },
});
const customer = data.getCustomerData(props.customerId);
const inputValue = computed({
    get: () => customer.contactData[props.what],
    set: (value) => data.setContactData(props.customerId, props.what, value),
});
const errors = () => customer.contactErrors[props.what];
const channelDone = () => customer.contactDone.includes(props.what);
</script>

<template>
    <form-row
        v-if="!channelDone()"
        :required="what === 'email'"
        :label="label"
        :for-id="`${what}-${customerId}-${clientId}`"
    >
        <template #input>
            <input
                :id="`${what}-${customerId}-${clientId}`"
                v-model="inputValue"
                class="form-input form-input--has-addon"
                type="text"
                :inputmode="inputmode"
                :maxlength="length"
            />
            <span class="form-input-addon">
                <svg-icon :name="icon" :size="1" />
            </span>
        </template>
        <template v-if="what === 'email' || errors()" #text>
            <div v-if="errors()" class="error">
                <svg-icon name="error" :size="1" />
                {{ errors() }}
            </div>
            <div v-if="what === 'email'">
                Bitte teilen Sie uns Ihre E-Mail-Adresse mit.
            </div>
        </template>
    </form-row>
    <div v-else class="thanks">
        <svg-icon :name="icon" :size="1" />
        Daten erfolgreich verschickt.
    </div>
</template>

<style lang="scss" scoped>
.error {
    display: flex;
    align-items: center;

    padding: #{get($sizes, "base") * 0.2} #{get($sizes, "base") * 0.2};
    border: get($sizes, "border") solid palette("state", "error");
    margin-top: #{get($sizes, "base") * 0.2};
    gap: #{get($sizes, "base") * 0.2};

    background-color: palette("state", "warning");
    color: palette("state", "error");

    font-weight: 400;
}

.thanks {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: #{get($sizes, "base") * 0.2} #{get($sizes, "base") * 0.2};
    border: get($sizes, "border") solid palette("state", "success");
    gap: #{get($sizes, "base") * 0.2};

    background-color: color.adjust(
        palette("state", "success"),
        $lightness: +60%
    );
    color: palette("state", "success");

    font-weight: 500;
    text-align: center;
}
</style>
