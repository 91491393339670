<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) reading value: double input
 *****************************************************************************/
import { ref, onBeforeMount } from "vue";
import { useReadingStore } from "Stores/reading";
import { validateKeyDown } from "@/handlers/useReading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const emit = defineEmits(["change"]);
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const readingValuePre = ref("");
const readingValuePost = ref("");
/**
 * @function on key down, check if the pressed key is valid
 * @param {KeyboardEvent} event
 */
const onKeyDownPre = (event) =>
    validateKeyDown(
        event,
        meterRegisterData.preCommaDigits,
        readingValuePre.value?.length
    );
/**
 * @function on key down, check if the pressed key is valid
 * @param {KeyboardEvent} event
 */
const onKeyDownPost = (event) =>
    validateKeyDown(
        event,
        meterRegisterData.postCommaDigits,
        readingValuePost.value?.length
    );
/**
 * @function if either pre or post comma gets changed, emit change so reading value
 * can be updated.
 */
const onChangeAny = () => {
    if (readingValuePost.value?.length > 0) {
        readingValuePost.value = readingValuePost.value
            .replace(",", "")
            .replace(".", "");
        emit("change", `${readingValuePre.value}.${readingValuePost.value}`);
    } else {
        if (
            typeof readingValuePre.value === "string" &&
            readingValuePre.value.length > 0
        ) {
            readingValuePre.value = readingValuePre.value
                .replace(",", "")
                .replace(".", "");
        }
        emit("change", `${readingValuePre.value}`);
    }
};

/**
 * @hook before mount, update the refs with values from store
 */
onBeforeMount(() => {
    const readingValue = data.getReadingValue(props.meterRegisterId).split(".");
    readingValuePre.value = readingValue[0];
    readingValuePost.value = readingValue[1];
});
</script>

<template>
    <div class="pre">
        <input
            :id="`meterReading-${meterRegisterId}`"
            v-model="readingValuePre"
            class="form-input form-input--meter"
            type="text"
            inputmode="numeric"
            :maxlength="meterRegisterData.preCommaDigits"
            formnovalidate
            @keydown="onKeyDownPre"
            @keyup="onChangeAny"
        />
        <ul class="digits">
            <li
                v-for="index in meterRegisterData.preCommaDigits"
                :key="index"
                class="digit"
            />
        </ul>
    </div>
    <div class="comma">,</div>
    <div class="post">
        <input
            :id="`meterReading-${meterRegisterId}-postcomma`"
            v-model="readingValuePost"
            class="form-input form-input--has-addon form-input--meter"
            type="text"
            inputmode="numeric"
            :maxlength="meterRegisterData.postCommaDigits"
            formnovalidate
            aria-label="Nachkommastellen"
            @keydown="onKeyDownPost"
            @keyup="onChangeAny"
        />
        <div
            class="form-input-addon form-input-addon--meter"
            aria-label="Einheit"
        >
            {{ meterRegisterData.meterUnit }}
        </div>
        <ul class="digits">
            <li
                v-for="index in meterRegisterData.postCommaDigits"
                :key="index"
                class="digit"
            />
        </ul>
    </div>
</template>

<style lang="scss" scoped>
$comma-width: 10px;
$addon-width: 46px;

.pre {
    display: flex;
    position: relative;

    width: 50%;

    .form-input {
        flex-grow: 1;
    }

    .digits {
        padding-right: 21px;
    }
}

.post {
    display: flex;
    position: relative;

    width: calc(50% - $comma-width);

    .form-input {
        flex-grow: 1;
    }

    .form-input-addon {
        flex: 0 0 $addon-width;
    }

    .digits {
        padding-right: #{$addon-width + 19px};
    }
}

input[type="text"] {
    z-index: get($z, "form");

    width: 100%;
}

.comma {
    flex: 0 0 10px;

    font-size: #{get($sizes, "base") * 1.5};
    font-weight: bold;
    text-align: center;
}
</style>
