<script setup>
/******************************************************************************
 * PageComponent: Confirm > MeterRegister
 *****************************************************************************/
import ConfirmMeterRegisterData from "./ConfirmMeterRegisterData.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const customerData = data.getCustomerData(meterRegisterData.customerId);
const meterHasOtherRegisters =
    data.meters.filter(
        (meter) => meter.meterNumber === meterRegisterData.meterNumber
    ).length > 1;
</script>

<template>
    <li class="meter-register">
        <header class="meter-register-information">
            <span
                v-if="
                    data.getCustomersForMeter(meterRegisterData.meterNumber)
                        .length > 1
                "
                class="meta"
            >
                <svg-icon name="customer" :size="0" />
                <span class="text">
                    Vertragsnehmer:
                    {{ data.getCustomerString(meterRegisterData.customerId) }},
                    Kundennummer:
                    <cite>{{ customerData.customerNo }}</cite>
                </span>
            </span>
            <span v-if="meterHasOtherRegisters" class="meta highlighted">
                Zählwerk {{ meterRegisterData.meterRegisterNumber }}
            </span>
            <span class="meta">{{ meterRegisterData.description }}</span>
            <span class="meta">
                <span
                    title="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                    aria-label="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                    >OBIS-Kennzahl:</span
                >
                {{ meterRegisterData.obis }}
            </span>
        </header>
        <confirm-meter-register-data
            :meter-register-id="meterRegisterId"
            :meter-number="meterRegisterData.meterNumber"
        />
    </li>
</template>

<style lang="scss" scoped>
$large: #{get($sizes, "base") * 1.5};

.meter-register {
    margin: $large 0;

    &:not(:last-child) {
        padding-bottom: $large;
        border-bottom: #{get($sizes, "border") * 2} solid get($client, "meterRegisterBoderColor");

        @include respond-to("medium") {
            border-bottom: #{get($sizes, "border") * 2} solid get($client, "meterRegisterBoderColor");
        }
    }

    @include respond-to("medium") {
        margin: $large 0;
    }
}

.meter-register-information {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: #{get($sizes, "base") * 1.5};
    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 0.75};
    }
}

.meta {
    display: flex;
    align-items: center;

    padding: #{get($sizes, "base") * 0.25} #{get($sizes, "base") * 0.5};
    border: get($sizes, "border") solid get($client, "meterMetaBorderColor");
    gap: #{get($sizes, "base") * 0.2};

    background-color: get($client, "meterMetaBg");
    color: get($client, "meterMetaColor");
    border-radius: get($sizes, "radius");

    cite {
        color: palette("brand1", "color");

        font-style: normal;
    }
}
</style>
