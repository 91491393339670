<script setup>
/******************************************************************************
 * Component: styled simple select dropdown
 *****************************************************************************/
import { ref, onMounted } from "vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
const menuOpen = ref(false);
const selectedValue = ref("");
const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    selected: {
        type: String,
        default: "",
    },
    forId: {
        type: String,
        required: true,
    },
});
const emit = defineEmits(["change"]);
/**
 * @function select option handler
 * @param value
 */
const select = (value) => {
    selectedValue.value = value;
    menuOpen.value = false;
    emit("change", value);
};
onMounted(() => {
    selectedValue.value = props.selected;
});
</script>

<template>
    <div class="select">
        <button
            :id="forId"
            :class="{ open: menuOpen }"
            @click="menuOpen = !menuOpen"
        >
            <span v-if="selectedValue">{{
                options.find((option) => option.value === selectedValue).label
            }}</span>
            <span v-else>Bitte wählen</span>
            <span class="caret" />
        </button>
        <ul v-if="menuOpen" class="menu">
            <li v-for="option in options" :key="option.value">
                <button
                    :class="{
                        selected: selectedValue === option.value,
                    }"
                    @click="select(option.value)"
                >
                    {{ option.label }}
                    <svg-icon
                        v-if="selectedValue === option.value"
                        name="done"
                    />
                </button>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
$inset: get($client, "appInsetShadowColor");
$border: get($sizes, "border");

.select {
    display: flex;
    position: relative;
    flex-direction: column;

    flex-grow: 1;

    > button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: #{get($sizes, "base") * 0.5} #{get($sizes, "base") * 0.75};
        border: get($sizes, "border") solid get($client, "inputBorderColor");

        background-color: get($client, "formSelectBg");
        color: get($client, "formSelectColor");
        border-radius: get($sizes, "radius");
        box-shadow: inset $border $border 0 $inset,
            inset $border -#{$border} 0 $inset,
            inset -#{$border} $border 0 $inset,
            inset -#{$border} -#{$border} 0 $inset;
        cursor: pointer;

        font-weight: 300;
        line-height: 1.4;

        transition: background-color get($timings, "fast") linear,
            color get($timings, "fast") linear,
            border-color get($timings, "fast") linear;

        &.open,
        &:hover {
            background-color: get($client, "fornSelectHoverBg");
            color: get($client, "formSelectHoverColor");
            border-color: get($client, "formSelectHoverBorderColor");
        }

        &.open {
            border-bottom-width: 0;
            margin-bottom: get($sizes, "border");

            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        > .caret {
            display: block;

            width: 0;
            height: 0;
            border-width: 6px 6px 0;

            border-style: solid;
            border-color: get($client, "formSelectCaretBg") transparent
                transparent;

            content: " ";

            transition: transform get($timings, "fast") linear;
        }

        &.open > .caret {
            transform: rotate(180deg);
        }
    }
}

.menu {
    position: absolute;
    top: calc(100% - get($sizes, "border"));
    z-index: get($z, "select");

    width: 100%;
    padding: 0 $border $border;
    border: get($sizes, "border") solid
        color.adjust(get($client, "inputBorderColor"), $lightness: -10%);
    border-top-width: 0;
    margin: 0;

    color: get($client, "formSelectColor");
    list-style: none;
    border-radius: 0 0 get($sizes, "radius") get($sizes, "radius");
    box-shadow: inset $border -#{$border} 0 $inset,
        inset -#{$border} -#{$border} 0 $inset;

    > li button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        padding: #{get($sizes, "base") * 0.5} #{get($sizes, "base") * 0.75};
        border: 0;

        background-color: get($client, "formSelectBg");
        cursor: pointer;

        font-weight: 300;
        text-align: left;

        transition: background-color get($timings, "fast") linear,
            color get($timings, "fast") linear;

        &:hover {
            background-color: color.adjust(
                get($client, "formSelectBg"),
                $lightness: -10%
            );
            color: color.adjust(
                get($client, "formSelectColor"),
                $lightness: -20%
            );
        }

        &.selected {
            background-color: get($client, "formSelectOptionSelectedBg");
            color: get($client, "formSelectOptionSelectedColor");
        }
    }

    > li:not(:last-child) button {
        border-bottom: get($sizes, "border") solid $inset;
    }
}
</style>
