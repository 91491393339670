<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) reading value: single input
 *****************************************************************************/
import { computed } from "vue";
import { useReadingStore } from "Stores/reading";
import { validateKeyDown } from "@/handlers/useReading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const emit = defineEmits(["change"]);
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const readingValue = computed({
    get: () => data.getReadingValue(props.meterRegisterId),
    set: (value) => {
        emit("change", value);
    },
});
/**
 * @function on key down, check if the pressed key is valid
 * @param {KeyboardEvent} event
 */
const onKeyDown = (event) =>
    validateKeyDown(
        event,
        meterRegisterData.preCommaDigits,
        readingValue.value.toString().length
    );
/**
 * @function on key up, remove "," and "."
 * @param {KeyboardEvent} event
 */
const onKeyUp = () =>
    (readingValue.value = readingValue.value.replace(",", "").replace(".", ""));
</script>

<template>
    <input
        :id="`meterReading-${meterRegisterId}`"
        v-model="readingValue"
        class="form-input form-input--has-addon form-input--meter"
        type="text"
        inputmode="numeric"
        :maxlength="meterRegisterData.preCommaDigits"
        formnovalidate
        @keydown="onKeyDown"
        @keyup="onKeyUp"
    />
    <ul class="digits">
        <li
            v-for="index in meterRegisterData.preCommaDigits"
            :key="index"
            class="digit"
        />
    </ul>
    <div class="form-input-addon form-input-addon--meter">
        {{ meterRegisterData.meterUnit }}
    </div>
</template>

<style lang="scss" scoped>
$addon-width: 46px;

input {
    z-index: get($z, "form");
}

.digits {
    padding-right: #{$addon-width + 19px};
}
</style>
