<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) image requirements
 *****************************************************************************/
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const fileTypes = data.config.allowedFileTypes;
</script>

<template>
    <div class="requirements">
        Zulässig:
        <span v-for="fileType in fileTypes" :key="fileType" class="filetype">{{
            fileType.toUpperCase()
        }}</span>
        <span class="filesize"
            >Max. {{ Math.round(data.config.imageMaxFileSize / 1024) }}MB</span
        >
    </div>
</template>

<style lang="scss" scoped>
.requirements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    padding: #{get($sizes, "base") * 0.5};
    border: get($sizes, "border") solid get($client, "inputBorderColor");
    margin-top: #{get($sizes, "base") * 0.2};
    gap: #{get($sizes, "base") * 0.2};

    background-color: get($client, "imageRequirementsBg");
    color: get($client, "imageRequirementsColor");
    border-radius: get($sizes, "radius");

    font-size: #{get($sizes, "base") - 2px};

    @include respond-to("medium") {
        margin-top: #{get($sizes, "base") * 0.4};
        gap: #{get($sizes, "base") * 0.4};
    }

    .filetype {
        padding: #{get($sizes, "base") * 0.25};

        background-color: get($client, "imageRequirementsFileTypeBg");
    }

    .filesize {
        padding: #{get($sizes, "base") * 0.25};
        margin-left: auto;

        background-color: get($client, "imageRequirementsFileSizeBg");
        color: get($client, "imageRequirementsFileSizeColor");
    }
}
</style>
